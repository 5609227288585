import { useState, useEffect } from 'react'

const useMouse = () => {
  const [mouse, setMouse] = useState()
  useEffect(() => {
    const handleMouse = e => {
      setMouse({ x: e.clientX, y: e.clientY })
    }
    window.addEventListener('mousemove', handleMouse, { passive: true })

    return () => {
      window.removeEventListener('mousemove', handleMouse)
    }
  })
  return mouse
}

export default useMouse
