import React, { useContext, useEffect } from 'react'
import styled, { css } from 'styled-components'

import Layout from '../components/Layout'
import Artworks from '../components/Artworks'
import About from '../components/About'
import Header from '../components/Header'
import AppContext from '../components/context/AppContext'
import useWindowSize from '../components/hooks/useWindowSize'

const Toggler = styled.div(
  ({ showMenu }) => css`
    transform: translateY(
      ${showMenu ? 'calc(var(--vh) * 3)' : 'calc((var(--vh) * -90))'}
    );
    transition: transform 1s ease;
  `
)

const FrontPage = () => {
  const { state, actions } = useContext(AppContext)
  const breakpoint = useWindowSize({ debounce: 200 })

  useEffect(() => {
    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    let vh = window.innerHeight * 0.01
    // Then we set the value in the --vh custom property to the root of the document

    document.documentElement.style.setProperty('--vh', `${vh}px`)
  }, [breakpoint])

  return (
    <Layout>
      <Toggler showMenu={state.showMenu}>
        <About />
        <Header state={state} actions={actions} />
        <Artworks />
      </Toggler>
    </Layout>
  )
}

export default FrontPage
