import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'
import { motion } from 'framer-motion'

import Editor from './editor/Editor'
import { bp, spacing, fonts } from '../styles/utilities'

const About = ({ className }) => {
  const data = useStaticQuery(query)
  const cv = data?.sanityNewCv
  const contact = data?.sanityAbout
  return (
    <div className={className}>
      {contact?._rawInfo && (
        <div className="about">
          <Editor blocks={contact._rawInfo} />
        </div>
      )}
      {cv?._rawCategories?.categories?.map(category => (
        <StyledCategoryItem category={category} key={category._key} />
      ))}
    </div>
  )
}

const variants = {
  hidden: {
    y: 20,
    opacity: 0,
    display: 'none',
    maxHeight: '0px'
  },
  visible: {
    y: 0,
    opacity: 1,
    display: 'block',
    maxHeight: '999999px',
    transition: {
      staggerChildren: 0.02
    }
  }
}

const horVariants = {
  hidden: { rotate: 0 },
  visible: { rotate: 180 }
}
const verVariants = {
  hidden: { rotate: 0 },
  visible: { rotate: -90 }
}

const itemVariants = {
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      type: 'spring',
      bounce: 0.35
    }
  },
  hidden: {
    opacity: 0,
    y: 50
  }
}

const CategoryItem = ({ className, category }) => {
  const [isOpen, setOpen] = useState(false)
  return (
    <div className={className}>
      <button
        className="trigger"
        onClick={() => setOpen(open => !open)}
        onMouseDown={e => e.preventDefault()} // To prevent focus on click but still keeps focus on tab
      >
        {category.title}
        <svg className="icon" viewBox="0 0 10 10">
          <motion.line
            variants={verVariants}
            animate={isOpen ? 'visible' : 'hidden'}
            stroke="black"
            className="ver"
            x1="5"
            y1="0"
            x2="5"
            y2="10"
          />
          <motion.line
            variants={horVariants}
            animate={isOpen ? 'visible' : 'hidden'}
            stroke="black"
            className="hor"
            x1="0"
            y1="5"
            x2="10"
            y2="5"
          />
        </svg>
      </button>
      <div className="content">
        <motion.div
          className="content"
          variants={variants}
          animate={isOpen ? 'visible' : 'hidden'}
        >
          {category?.periods?.periods?.map?.(item => (
            <motion.div
              variants={itemVariants}
              className={`item ${item.title ? '' : 'item--has-no-title'}`}
              key={item._key}
            >
              {item?.title && <p className="time">{item.title}</p>}
              <div className="item-content">
                <Editor blocks={item.content} />
              </div>
            </motion.div>
          ))}
        </motion.div>
      </div>
    </div>
  )
}

const StyledCategoryItem = styled(CategoryItem)(
  ({ theme }) => css`
    ${theme.border.small()}
    ${spacing.md('py')}
    ${spacing.md('px')}
    ${fonts.body()}

    .trigger {
      ${fonts.title()}
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .icon {
      height: ${theme.icons.small};
    }

    .content {
      pointer-events:none;
      /* ${spacing.md('pt')} */
    }

    .item {
      ${spacing.md('mb')}
      ${fonts.body()}

      ${bp.above.md`
        display: grid;
        grid-template-columns: 100px calc(100% - 100px);
        &--has-no-title {
          display: block;
        }
      `}

      &:first-of-type {
        ${spacing.lg('mt')}
        ${bp.below.md`
          .time {
            padding-top: 0;
          }
        `}
      }
    }

    .time {
      white-space: nowrap;
      ${spacing.lg('mr')}

      ${bp.below.md`
        ${spacing.xs('mb,pt')}
      `}
    }

    .desc {
      ${spacing.xs('mb')}

      ${bp.below.md`
      ${spacing.sm('mb')}

      `}
    }
  `
)

export default styled(About)(
  ({ theme }) => css`
    position: relative;
    height: calc(var(--vh) * 90);
    /* padding-bottom: 150px; */
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;

    .about {
      ${spacing?.gutter?.('mt')};
      ${spacing?.lg?.('mb')};
      ${spacing.md('px')}
      * {
        ${fonts.title()};
      }
    }
  `
)

export const query = graphql`
  {
    sanityNewCv(_id: { eq: "newCv" }) {
      _rawCategories(resolveReferences: { maxDepth: 10 })
    }
    sanityAbout(_id: { eq: "about" }) {
      _rawInfo(resolveReferences: { maxDepth: 10 })
      id
    }
  }
`
