import React from 'react'

import styled, { ThemeProvider } from 'styled-components'
import theme from '../styles/themes'
import { GlobalStyle } from '../styles/utilities/Global'
import Cursor from './Cursor'
import SEO from './SEO'

const Layout: React.FC = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <SEO />
      <GlobalStyle />
      <Cursor />
      <div className="Site">
        <SiteContent>{children}</SiteContent>
      </div>
    </ThemeProvider>
  )
}

const SiteContent = styled.div`
  max-height: 100vh;
`

export default Layout
