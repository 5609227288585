import React, { useContext, useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { motion } from 'framer-motion'

import useCursor from './hooks/useCursor'
import { bp } from '../styles/utilities'
import AppContext from './context/AppContext'

const variants = {
  hovering: {
    scale: 2
  },
  normal: {
    scale: 1
  },
  pressed: {
    scale: 1.5
  }
}

const StyledCursor = styled(motion.div)(
  ({ isActive, size }) => css`
    position: fixed;
    z-index: 99999;
    /* mix-blend-mode: difference; */
    width: ${size}px;
    height: ${size}px;
    border-radius: ${size}px;
    background: blue;
    pointer-events: none;

    ${isActive &&
      css`
        ${console.log('im active')};
        background: orange;
        transform: scaleX(6);
      `}

    ${bp.only.xs`display: none`}
  `
)

const EVENT_OPTIONS = {
  passive: true
}

const Cursor = ({ size = 20 }) => {
  const mouse = useCursor()
  const [isHovering, setIsHovering] = useState(false)
  const [isActive, setIsActive] = useState(false)
  const { state } = useContext(AppContext)

  useEffect(() => {
    const items = [
      ...document.querySelectorAll('button, a, .artwork__item, .lg-icon')
    ]

    items.forEach(item => {
      item.addEventListener(
        'mouseover',
        () => {
          setIsHovering(true)
        },
        EVENT_OPTIONS
      )
      item.addEventListener(
        'mouseout',
        () => {
          setIsHovering(false)
        },
        EVENT_OPTIONS
      )
    })

    document.documentElement.addEventListener(
      'mousedown',
      () => {
        setIsActive(true)
      },
      EVENT_OPTIONS
    )
    document.documentElement.addEventListener(
      'mouseup',
      () => {
        setIsActive(false)
      },
      EVENT_OPTIONS
    )

    return () => {
      items.forEach(item => {
        item.removeEventListener(
          'mouseover',
          () => {
            setIsHovering(true)
          },
          EVENT_OPTIONS
        )
        item.removeEventListener(
          'mouseout',
          () => {
            setIsHovering(false)
          },
          EVENT_OPTIONS
        )
      })
      document.documentElement.removeEventListener(
        'mousedown',
        () => setIsActive(true),
        EVENT_OPTIONS
      )
      document.documentElement.removeEventListener(
        'mouseup',
        () => setIsActive(true),
        EVENT_OPTIONS
      )
    }
  }, [state.isGalleryOpen])

  if (!mouse) return null

  return (
    <StyledCursor
      size={size}
      variants={variants}
      animate={isActive ? 'pressed' : isHovering ? 'hovering' : 'normal'}
      style={{
        top: `${mouse.y - size / 2}px`,
        left: `${mouse.x - size / 2}px`
      }}
    />
  )
}

export default Cursor
