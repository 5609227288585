import React, { useEffect, useRef } from 'react'
import styled, { css } from 'styled-components'
import { Button } from './elements'
import { spacing, fonts, bp } from '../styles/utilities'
import useWindowSize from './hooks/useWindowSize'

const Header: React.FC<{ className: string; state: any; actions: any }> = ({
  className,
  state,
  actions
}) => {
  const container = useRef<HTMLDivElement | null>(null)
  const breakpoint = useWindowSize({ debounce: 200 })
  useEffect(() => {
    let height = container?.current?.getBoundingClientRect?.().height

    document.documentElement.style.setProperty('--header-height', `${height}px`)
  }, [breakpoint])
  return (
    <div className={className} ref={container}>
      <div className="logo" onClick={actions.toggleMenu}>
        Marthe Elise Stramrud
      </div>
      <Button
        onClick={actions.toggleMenu}
        modifiers={['link', 'large']}
        onMouseDown={e => e.preventDefault()}
      >
        {state.showMenu ? 'Works' : 'About'}
      </Button>
    </div>
  )
}

export default styled(Header)(
  ({ theme, state }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: -1px;
    width: 100%;
    ${spacing.gutter('p')};
    background-color: ${theme.colors.background};
    ${bp.above.md`
        transform: none;
      `}
    ${fonts.title()};

    ${Button} {
      padding: 0;
      ${fonts.title()};
    }
  `
)
