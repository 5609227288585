import React, { useState, useLayoutEffect, useContext } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import styled, { css } from 'styled-components'
import { pure } from 'recompose'
import useHorizontal from '@oberon-amsterdam/horizontal/hook'
import 'lightgallery.js/dist/css/lightgallery.css'
import { LightgalleryProvider, LightgalleryItem } from 'react-lightgallery'

import { spacing, fonts, bp } from '../styles/utilities'

import SanityImage from './editor/SanityImage'
import useWindowSize from './hooks/useWindowSize'
import AppContext from './context/AppContext'

const Artworks = () => {
  const data = useStaticQuery(query)
  const artworks = data?.allSanityFrontpage?.nodes[0]?.artworks || []
  const breakpoint = useWindowSize({ debounce: 200 })
  let isDevice
  if (typeof window === 'undefined') {
    isDevice = false
  } else {
    isDevice = breakpoint.innerWidth < 800
  }
  const Wrapper = isDevice ? ArtworksMobile : StyledArtworksDesktop
  const { actions } = useContext(AppContext)
  return (
    <LightgalleryProvider
      galleryClassName="gallery"
      lightgallerySettings={{
        mode: 'lg-fade',
        speed: 100,
        hideBarsDelay: 800,
        download: false,
        thumbnail: false,
        actualSize: false
      }}
      onAfterOpen={() => {
        actions.toggleGallery(true)
      }}
      onCloseAfter={() => {
        actions.toggleGallery(false)
      }}
    >
      <Wrapper breakpoint={breakpoint}>
        {artworks
          .filter(artwork => artwork && artwork.hasOwnProperty('id'))
          .map(artwork => {
            return (
              <Artworks.Item
                key={artwork._id}
                artwork={artwork}
                breakpoint={breakpoint}
              />
            )
          })}
      </Wrapper>
    </LightgalleryProvider>
  )
}

const VerticalScrollContainer = styled.div`
  height: 100vh;
  padding-top: 15vh;
  overflow: auto;

  ${bp.below.md`
    padding-top: 0;
  `}
`

const ArtworksMobile = ({ children }) => {
  return <VerticalScrollContainer>{children}</VerticalScrollContainer>
}

const ArtworksDesktop = ({ className, children, breakpoint }) => {
  // I'd prefer to use useRef, but we need a rerender to be triggered
  const [container, setContainer] = useState()

  useHorizontal({ container: container, preventVerticalScroll: false })

  // settings: https://sachinchoolur.github.io/lightgallery.js/docs/api.html
  return (
    <div
      className={className}
      ref={ref => {
        setContainer(ref)
      }}
    >
      {children}
    </div>
  )
}

const getCalculatedDimensions = image => {
  if (typeof window === 'undefined') {
    return {
      width: 'auto'
    }
  }
  const isDevice = window.innerWidth < 800
  const isLandscape = image?.aspectRatio > 1

  if (isLandscape) {
    return {
      minWidth: isDevice
        ? '100%'
        : `${image?.aspectRatio * (window.innerHeight / 2)}px`
    }
  } else {
    return {
      minWidth: isDevice
        ? '100%'
        : `${image?.aspectRatio * (window.innerHeight * 0.7)}px`
    }
  }
}

const ArtworksItem = ({
  className,
  artwork: { image, _rawMeasures: measures, title, year, _id: id, materials }
}) => {
  const [dimensions, setDimensions] = useState(null)

  useLayoutEffect(() => {
    const calcedDimensions = getCalculatedDimensions(image?.asset?.fluid)
    // console.log('calcedDimensions', calcedDimensions)
    setDimensions(calcedDimensions)
  }, [image])

  return (
    <div id={id} className={`${className} artwork__item`}>
      <div style={{ ...dimensions, width: '100%' }}>
        <div className="image">
          <LightgalleryItem group="any" src={image.asset.url}>
            <SanityImage
              node={image}
              className="image"
              aspectRatio="original"
            />
          </LightgalleryItem>
        </div>
        <div className="info">
          <span className="title">{title}</span>
          {measures && measures.width && measures.height && (
            <span className="text">
              , {measures.width}
              {measures.unit} x {measures.height}
              {measures.unit}{' '}
              {measures.dept && `x ${measures.dept}${measures.unit}`}
            </span>
          )}
          {materials && materials.length > 0 && (
            <span className="text">, {materials}</span>
          )}
          {/* {description && description.length > 0 && (
          <span className="description">, {description}</span>
        )} */}
          {year && (
            <span>
              ,&nbsp;
              {year}
            </span>
          )}
        </div>
      </div>
    </div>
  )
}

const StyledArtworksDesktop = pure(
  styled(ArtworksDesktop)(
    ({ theme }) => css`
      position: relative;
      height: calc(var(--vh) * 100 - var(--header-height));

      ${bp.below.md`
        padding: 0 10px;
      `}

      ${bp.above.md`
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        align-items: center;
      `}
    `
  )
)

export default Artworks

Artworks.Item = styled(ArtworksItem)(
  ({ theme }) => css`
    ${bp.below.md`
        ${spacing.lg('mt')}
        ${spacing.gutter('mx')}
      `}

    ${bp.above.md`
      ${spacing.gutter('mx')}
    `}

    .info {
      margin-top: 0.6em;
      ${fonts.body()}
    }
  `
)

export const query = graphql`
  {
    allSanityFrontpage {
      nodes {
        artworks {
          _rawMeasures(resolveReferences: { maxDepth: 10 })
          title
          year
          _id
          image {
            asset {
              url
              fluid(maxWidth: 2000) {
                ...GatsbySanityImageFluid
              }
            }
          }
          materials
        }
      }
    }
  }
`
