import React from 'react'
import { Helmet } from 'react-helmet'
import { graphql, useStaticQuery } from 'gatsby'

const SEO = () => {
  const data = useStaticQuery(query)
  const {
    sanityFrontpage: {
      seo: { description, title, image }
    }
  } = data

  const canonicalUrl = 'https://www.martheelise.com/'

  return (
    <Helmet>
      {/* Font preloading */}
      <link
        rel="preload"
        href="/fonts/TiemposTextWeb-Regular.woff2"
        as="font"
        type="font/woff2"
        crossOrigin
      />
      <link
        rel="preload"
        href="/fonts/TiemposTextWeb-RegularItalic.woff2"
        as="font"
        type="font/woff2"
        crossOrigin
      />
      <link rel="stylesheet" href="/fonts/fonts.css" />
      {/* SEO */}
      <title>Marthe Elise Stramrud</title>
      <link rel="canonical" href={canonicalUrl} />
      {description && <meta name="description" content={description} />}
      {description && <meta property="og:description" content={description} />}
      {description && <meta name="twitter:description" content={description} />}
      {image?.asset?.fixed && (
        <meta name="image" content={image.asset.fixed.src} />
      )}
      {image?.asset?.fixed && (
        <meta itemprop="image" content={image.asset.fixed.src} />
      )}
      {image?.asset?.fixed && (
        <meta property="og:image" content={image.asset.fixed.src} />
      )}
      {image?.asset?.fixed && (
        <meta property="og:image:width" content={image.asset.fixed.width} />
      )}
      {image?.asset?.fixed && (
        <meta property="og:image:height" content={image.asset.fixed.height} />
      )}
      <meta property="og:locale" content="en_US" />
      <meta property="og:url" content={canonicalUrl} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:title" content={title} />
      <html lang="en" />
    </Helmet>
  )
}

export default SEO

const query = graphql`
  {
    sanityFrontpage(_id: { eq: "frontpage" }) {
      seo {
        title
        description
        image {
          asset {
            url
            fixed(width: 1200) {
              ...GatsbySanityImageFixed
            }
          }
        }
      }
    }
  }
`
