import React from 'react'
import Img from 'gatsby-image'

const SanityImage = ({ node, maxWidth = 1440, aspectRatio, ...props }) => {
  if (!node?.asset) {
    return null
  }

  return (
    <Img
      key={node.asset?.id}
      fluid={node?.asset?.fluid}
      alt={node.alt}
      {...props}
    />
  )
}

export default SanityImage
