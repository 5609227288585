import React from 'react'
import BaseBlockContent from '@sanity/block-content-to-react'

import Quote from './Quote'
import Figure from './Figure'
import { H3, H2, P } from '../elements'

export const serializers = {
  types: {
    block(props) {
      if (props.node.children.text && props.node.children.text.length === 0)
        return null
      switch (props.node.style) {
        case 'h2':
          return <H2>{props.children}</H2>

        case 'h3':
          return <H3>{props.children}</H3>

        case 'large':
          return <P modifiers="large">{props.children}</P>

        case 'small':
          return (
            <P as="small" modifiers="small">
              {props.children}
            </P>
          )

        case 'span':
          return <P as="span">{props.children}</P>

        default:
          return <P>{props.children}</P>
      }
    },
    quote(props) {
      if (!props.node.content) return null
      return <Quote quote={props.node} />
    },
    figure(props) {
      return <Figure node={props.node} />
    }
  },
  marks: {
    link(props) {
      const link = props?.mark?.externalLink?.url || props?.mark?.reference
      if (!link) return props.children
      return (
        <a href={link}>
          {props.children ||
            props?.mark?.title ||
            props?.mark?.reference?.title}
        </a>
      )
    }
  }
}

const Editor = ({ blocks, className }) => {
  return (
    <div className={`Editor  ${className && className}`}>
      <BaseBlockContent
        className="Editor__blocks"
        blocks={blocks}
        serializers={serializers}
      />
    </div>
  )
}

export default Editor
