import { css } from 'styled-components'

import { bp } from './Breakpoints'

export const fonts = {
  title: () => ({ theme }) => css`
    ${theme.fonts.small()}
    ${bp.above.md`
          ${theme.fonts.medium()}
        `}
    ${bp.above.lg`
        ${theme.fonts.large()}
      `}
  `,
  body: () => {
    return function({ theme }) {
      return css`
      ${theme.fonts.xs()}
        ${bp.above.md`
          ${theme.fonts.small()}
        `}
        ${bp.above.lg`
          ${theme.fonts.medium()}
        `}
      `
    }
  },
  small: () => ({ theme }) => css`
    ${theme.fonts.xs()}
  `
}

export const globalTypeStyle = ({ theme }) => css`
  h1,
  .h1 {
    ${fonts.title()}
  }

  h2,
  .h2 {
    ${fonts.title()}
  }

  h3,
  .h3 {
    ${fonts.title()}
  }

  small,
  .text-small {
    ${fonts.body()}
  }

  strong {
    font-weight: bold;
  }

  *:focus {
    outline-color: ${theme.colors.primary};
  }

  a {
    font-family: ${theme.fontFamily.serif};
    cursor: pointer;
    position: relative;
    text-decoration: none;
    color: currentColor;
  }

  .link {
    display: inline-block;
    border-bottom: ${theme.colors.secondary};
    transition: border-color ${theme.trans.fast};

    &:hover {
      border-color: transparent;
    }
  }

  .sans {
    font-family: ${theme.fontFamily.sans};
  }

  .serif {
    font-family: ${theme.fontFamily.serif};
  }
`
