import { css } from 'styled-components'

import { remSize } from './utilities/Converters'

export const colors = {
  primary: 'black',
  secondary: 'black',
  text: 'black',
  border: 'black',
  background: '#f6f6f6'
}

export const breakpoints = {
  xs: 0,
  sm: 550,
  md: 870,
  lg: 1200,
  xl: 1600,
  xxl: 1800
}

export const spacingUnit = {
  xs: remSize(5),
  sm: remSize(10),
  md: remSize(20),
  lg: remSize(30),
  xl: remSize(40),
  section: remSize(240),
  gutter: remSize(30)
}

export const fontFamily = {
  sans: `'Tiempos', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
  Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;`,
  serif: `'Tiempos', times, serif`
}

export const fonts = {
  large: () =>
    css`
      font-size: ${remSize(25)};
      line-height: 1.3;
    `,
  medium: () =>
    css`
      font-size: ${remSize(22)};
      line-height: 1.3;
    `,
  small: () =>
    css`
      font-size: ${remSize(18)};
      line-height: 1.3;
    `,
  xs: () =>
    css`
      font-size: ${remSize(16)};
      line-height: 1.3;
    `
}

export const aspect = {
  portrait: (7 / 6) * 100,
  landscape: (2 / 3) * 100,
  square: 100,
  widescreen: (9 / 16) * 100,
  panorama: (11 / 16) * 100
}

export const contentWidth = {
  small: remSize(600),
  large: remSize(1200)
}

export const icons = {
  small: remSize(15),
  medium: remSize(80),
  large: remSize(160)
}

export const trans = {
  fast: `0.1s ease`,
  slow: `1s ease`
}

export const borderWidth = {
  small: remSize(1),
  large: remSize(3)
}

// TODO: Add dynamic property without error
export const border = {
  large: (key = 'border-bottom') => ({ theme }) => css`
    ${key}: ${theme.borderWidth.large} solid ${theme.colors.border};
  `,
  small: (key = 'border-bottom') => ({ theme }) => css`
    ${key}: ${theme.borderWidth.small} solid ${theme.colors.border};
  `
}

export const theme = {
  colors,
  breakpoints,
  spacingUnit,
  fontFamily,
  fonts,
  aspect,
  contentWidth,
  trans,
  icons,
  borderWidth,
  border
}

export const darkTheme = {
  ...theme,
  colors: {
    primary: 'green',
    secondary: 'orange',
    text: 'white',
    border: 'red',
    background: '#b2b2b2'
  },
  breakpoints: {
    xs: 0,
    sm: 200,
    md: 400,
    lg: 600,
    xl: 800,
    xxl: 900
  },
  defaultStyle: ({ theme }) => css`
    html {
      font-size: 75%;
    }
    body {
      background: ${theme.colors.background};
    }
  `
}

export default theme

/**
 * Way to split up and reuse vals
 */

// export const createBorder = theme => ({
//   borderWidth: 1,
//   borderStyle: 'solid',
//   borderColor: colors.primary,
//   border: '1px solid black'
// })

// export const theme = {
//   border: createBorder({ colors: { primary: 'black' } })
// }
// export const darkTheme = {
//   border: createBorder({ colors: { primary: 'orange' } })
// }
